import { getAction, deleteAction, putAction, postAction, httpAction } from '@/api/manage'

// 建筑专家
const gaginExpertList = (params) =>  getAction('/expert/listWeb', params)   //pageSize pageNo projectStageIds serviceScopeIds


// 数据字典
const gainDictCode = (dictCode) => getAction(`/sys/dict/getDictItems/${dictCode}`, { dictCode: dictCode })

// 获取课程
// 1级分类(1-人才培训,2-基础人员培训,3-新考培训,4-数智管理,5法律服务,6-数智财税,7-数智建造)
const gainCourseList = (params) =>  getAction('/course/listWeb', params)

// 获取新闻
// 资讯分类(0-行业资讯,1-公告,2-最新政策,3-智能建造,4-法律服务,5-新规速递)
const gainNewsList = (params) => getAction('/news/list', params)


 
export {
    gaginExpertList,
    gainDictCode,
    gainCourseList,
    gainNewsList,
}