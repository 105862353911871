import Vue from 'vue'
// import * as dayjs from 'dayjs'

Vue.filter('ellipsis', function(value, length) {
    if(!value){
        return ''
    }
    if(value.length > length) {
        return value.slice(0, length) + '...';
    }
    return value;
})

Vue.filter('year', function(value) {
    // console.log(123, value)
    return parseInt(value / 365);
})

// 176 3573 4534
Vue.filter('phonefilter', function(value) {
    if(!value){
        return ''
    }
    value = value.toString().replace(/^(\d{3})(\d{4})(\d{4})$/, function(val, $1, $2, $3){
        return $1 + ' **** ' + $3;
    })
    return value;
})


Vue.filter('dayjs', function(dataStr, pattern = 'YYYY-MM-DD HH:mm:ss') {
    return dayjs(dataStr).format(pattern)
})