import Vue from 'vue'
import Router from 'vue-router'
import { ACCESS_TOKEN } from "@/store/mutation-types";
const Home = () => import('../views/Home/index')
const law = () => import('../views/Home/flfw/index')
const FastLogin = () => import('../views/FastLogin/index')
const register = () => import('../views/register/index')
const login = () => import('../views/Login/index')
const personal = () => import('../views/Personal/index')
const attestation = () => import('../views/Personal/Attestation/index')


const setting = () => import('../views/Personal/setting/index')
const settingName = () => import('../views/Personal/setting/settingName/index')

const consult = () => import('../views/Home/szgl/consult/index')
const treaty = () => import('@/components/treaty')
const order = () => import('@/views/Home/szgl/order/index')
const coursedetail = () => import('@/views/Home/szgl/courseDetail/index')
const courseList = () => import('@/views/Home/szgl/courseList/index')
const consultlibrary = () => import('@/views/Home/flfw/consultlibrary/index')


const newList = () => import('../views/Home/flfw/newList/index')
const newsdetail = () => import('../views/Home/flfw/newsDetail/index')


const newscontent = () => import('../views/Home/flfw/newList/newscontent')
const qadetail = () => import('@/views/Home/flfw/consultlibrary/qadetail')
const myfirst = () => import('@/views/Personal/myFirst/index')
const personInfo = () => import('@/views/Personal/myFirst/personInfo/index')
const firstclass = () => import('@/views/Personal/myFirst/firstclass/index')
const secondclass = () => import('@/views/Personal/myFirst/firstclass/secondclass')
const continuingeducation = () => import('@/views/Personal/myFirst/continuingeducation/index')
const mycourse = () => import('@/views/Personal/myFirst/mycourse/index')
const studyvideo = () => import('@/views/Personal/myFirst/mycourse/studyvideo')
const faceRec = () => import('@/views/Personal/myFirst/mycourse/faceRec')
const startRecAnd = () => import('@/views/Personal/myFirst/mycourse/startRecAnd')
const startRecIos = () => import('@/views/Personal/myFirst/mycourse/startRecIos')
const exam = () => import('@/views/Personal/myFirst/mycourse/exam')
const myorder = () => import('@/views/Personal/myFirst/myorder/index')
const classedit = () => import('@/views/Personal/myFirst/firstclass/firstclassedit')
const bill = () => import('@/views/Personal/myFirst/myorder/bill')
const celist = () => import('@/views/Home/onedu/celist')
const perinfo = () => import('@/views/Home/onedu/perinfo')
const activity = () => import('@/views/activity/index')
const main = () => import('@/views/activity/main')
const tvLiveList = () => import('@/views/activity/live')
const tvLiveDetail = () => import('@/views/activity/liveDetail')
const buyDetail  = () => import('@/components/coupon')
const badayuan  = () => import('@/views/Home/badayuan/index')
const rightMeans = () => import('@/views/Personal/myFirst/rightmeans/index')
const safeAbc  = () => import('@/views/Personal/myFirst/safeabc/index')
const toTab  = () => import('@/views/Personal/myFirst/toTab')
const bdyBm = () => import('@/views/Home/badayuan/bdyBm')
const aqyBm = () => import('@/views/Home/anquanyuan/aqyBm')
const aqLearning = () => import('@/views/Personal/myFirst/safeabc/learning')
const aqyexam = () => import('@/views/Personal/myFirst/safeabc/exam')
Vue.use(Router)
const VueRouter = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      redirect: '/Home',
    },
    { // 首页
      path: '/Home',
      name: 'Home',
      component: Home
    },
    {//法律服务
      path: '/law',
      name: 'law',
      component: law
    },
    { // 快捷登录
      path: '/FastLogin',
      name: 'FastLogin',
      component: FastLogin
    },
    { // 注册
      path: '/register',
      name: 'register',
      component: register
    },
    { // 登录
      path: '/login',
      name: 'login',
      component: login
    },
    {// 个人中心
      path: '/personal',
      name: 'personal',
      component: personal
    },
    { //企业认证
      path: '/attestation',
      name: 'attestation',
      component: attestation
    },



    {//设置
      path: '/setting',
      name: 'setting',
      component: setting
    },
    {//修改姓名
      path: '/settingName',
      name: 'settingName',
      component: settingName
    },
    {//专家咨询
      path: '/consult',
      name: 'consult',
      component: consult
    },
    {//协议
      path: '/treaty',
      name: 'treaty',
      component: treaty
    },
    {//定制
      path: '/order',
      name: 'order',
      component: order
    },
    {//课程详情
      path: '/coursedetail',
      name: 'coursedetail',
      component: coursedetail
    },
    {//课程更多
      path: '/courseList',
      name: 'courseList',
      component: courseList
    },
  
    {//咨询库
      path: '/consultlibrary',
      name: 'consultlibrary',
      component: consultlibrary
    },

    { //  新闻列表
      path: '/newList',
      name: 'newList',
      component: newList
    },
    {//新闻详情
      path: '/newsdetail',
      name: 'newsdetail',
      component: newsdetail
    },

    { // 新闻筛选页面
      path: '/newscontent',
      name: 'newscontent',
      component: newscontent
    },
    {//咨询详情
      path: '/qadetail',
      name: 'qadetail',
      component: qadetail
    },
    {//我的一建
      path: '/myfirst',
      name: 'myfirst',
      component: myfirst
    },
    {//个人资料
      path: '/personInfo',
      name: 'personInfo',
      component: personInfo
    },
    {//一级建造师资质证书
      path: '/firstclass',
      name: 'firstclass',
      component: firstclass
    },
       {//二级建造师资质证书
        path: '/secondclass',
        name: 'secondclass',
        component: secondclass
      },
    {//继续教育证书
      path: '/continuingeducation',
      name: 'continuingeducation',
      component: continuingeducation
    },
    {//我的课程（一建）
      path: '/mycourse',
      name: 'mycourse',
      component: mycourse
    },
    {//我的订单
      path: '/myorder',
      name: 'myorder',
      component: myorder
    },
    {//添加证书
      path: '/classedit',
      name: 'classedit',
      component: classedit
    },
    {//发票
      path: '/bill',
      name: 'bill',
      component: bill
    },
    {//报名
      path: '/celist',
      name: 'celist',
      component: celist
    },
    {//完善报名信息           
      path: '/perinfo',
      name: 'perinfo',
      component: perinfo
    },
    {// 学习视频
      path: '/studyvideo',
      name: 'studyvideo',
      component: studyvideo
    },
    {// 考试
      path: '/exam',
      name: 'exam',
      component: exam
    }, {//人脸识别
      path: '/faceRec',
      name: 'faceRec',
      component: faceRec
    },
    {//人脸开始识别安卓
      path: '/startRecAnd',
      name: 'startRecAnd',
      component: startRecAnd
    },
    {//人脸开始识别ios
      path: '/startRecIos',
      name: 'startRecIos',
      component: startRecIos
    },

    {//活动
      path: '/double11',
      name: 'double11',
      component: activity,

    },
    {//活动详情
      path: '/double11/main',
      name: 'main',
      component: main,

    }, {//直播list
      path: '/tvLiveList',
      name: 'tvLiveList',
      component: tvLiveList,
    },{//直播detail
      path: '/tvLiveDetail',
      name: 'tvLiveDetail',
      component: tvLiveDetail,
    },

    {//购买详情
      path:'/buyDetail',
      name: 'buyDetail',
      component: buyDetail,
    },
    {//八大员
      path:'/badayuan',
      name: 'badayuan',
      component: badayuan,
    },
    {//个人中心-培训教育-建筑八大员资料
      path:'/rightMeans',
      name: 'rightMeans',
      component: rightMeans,
    },
     {//个人中心-培训教育-安全员ABC
      path:'/safeAbc',
      name: 'safeAbc',
      component: safeAbc,
    },
    {//个人中心-培训教育-我的课程Tab页
      path:'/toTab',
      name: 'toTab',
      component: toTab,
    },
    {//八大员报名 
      path:'/bdyBm',
      name: 'bdyBm',
      component: bdyBm,
    },
    {//安全员报名
      path:'/aqyBm',
      name: 'aqyBm',
      component:aqyBm, 
    },
    {//安全员学习
      path:"/aqLearning",
      name: 'aqLearning',
      component:aqLearning, 
    },
    {//安全员模拟考
      path:'/aqyexam',
      name: 'aqyexam',
      component:aqyexam, 
    }
  ]
})

export default VueRouter


VueRouter.beforeEach((to, from, next) => {
  const token = Vue.ls.get(ACCESS_TOKEN)
  if (!token) {
    if (to.path == '/personal') {
      VueRouter.push('/FastLogin')
    }
  }
  if (from.name == 'exam' || from.name == 'aqyexam') {
    window.sessionStorage.removeItem('subject');
  }
  next()
})
