// import Vue from 'vue'
import { axios } from '@/utils2/http'
import { request } from '@/utils2/request'


//post
export function postAction(url,parameter) {
    return axios({
      url: url,
      method:'post' ,
      data: parameter
    })
}


//get
export function getAction(url,parameter) {
    return axios({
      url: url,
      method: 'get',
      params: parameter
    })
}

/**
 * 文件上传 用于富文本上传图片
 * @param url
 * @param parameter
 * @returns {*}
 */
export function uploadAction (url,parameter){
  return request({
    url: url,
    data: parameter,
    method:'post' ,
    headers: {
      'Content-Type': 'multipart/form-data',  // 文件上传
    },
  })
}

//put
export function putAction(url,parameter) {
  return axios({
    url: url,
    method:'put',
    data: parameter
  })
}

// CTC上传图片



/**
 * 获取文件服务访问路径
 * @param avatar
 * @param subStr
 * @returns {*}
 */
export function getFileAccessHttpUrl(avatar,subStr) {
  if(!subStr) subStr = 'http'
  if(avatar && avatar.startsWith(subStr)){
    return avatar;
  }else{
    if(avatar &&　avatar.length>0 && avatar.indexOf('[')==-1){
      return window._CONFIG['staticDomainURL'] + "/" + avatar;
    }
  }
}