import Vue from 'vue'
import App from './App.vue'
import router from './router/index'
import store from './store/index'
import 'mint-ui/lib/style.css'
import axios from 'axios'//引入axios
import Storage from 'vue-ls'
import Mint from 'mint-ui';
import Vant from 'vant';
import Area from '@/utils2/Area'
import NPlayer from "@nplayer/vue";
import ys from '@/utils2/ys'

// import animated from 'animate.css'
// import ajaxFun from './service/requestFun'
import {
  Button, Tabbar, TabItem, TabContainer, TabContainerItem,
  Cell, Toast, Picker,
  Loadmore, InfiniteScroll, Spinner,
  DatetimePicker, Switch, MessageBox, Popup,Progress
} from 'mint-ui'
import LinkageBase from './components/linkageBase';
import '@/utils2/filter'  //过滤器
import PickerS from './components/PickerS/index'
import EventQueue from './common/js/eventQueue'
import $ from 'jquery'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vant/lib/index.css';
import vueBus from '@/utils2/vueBus';
// Vue.use(animated);
Vue.use(PickerS);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(LinkageBase);
Vue.use(InfiniteScroll);
Vue.use(Mint);
Vue.use(Vant);
Vue.use(NPlayer);
Vue.use(vueBus);

Vue.use(Storage, {
  namespace: 'ctc_web__', // key prefix
  name: 'ls', // name variable Vue.[ls] or this.[$ls],
  storage: 'local', // storage name session, local, memory
});
Vue.component(Spinner.name, Spinner);
Vue.component(Loadmore.name, Loadmore);
Vue.component(Tabbar.name, Tabbar);
Vue.component(TabItem.name, TabItem);
Vue.component(Button.name, Button)
Vue.component(TabContainer.name, TabContainer);
Vue.component(TabContainerItem.name, TabContainerItem);
Vue.component(Cell.name, Cell);
Vue.component(Picker.name, Picker);
Vue.component(DatetimePicker.name, DatetimePicker);
Vue.component(Switch.name, Switch);
Vue.component(MessageBox.name, MessageBox); //弹框组件  页面调用无效
Vue.component(Popup.name, Popup);
Vue.component(Progress.name, Progress);
let area = new Area();
Vue.prototype.$area = area
Vue.prototype.$vueBus= vueBus
Vue.prototype.$ys= ys
Object.assign(Vue.prototype, {
  $toast: Toast,
  $eventQueue: new EventQueue()
});
Vue.config.productionTip = false
Vue.prototype.$axios = axios;//把axios挂载到vue上
new Vue({
  router,
  store,
  // mounted() {
  //   store.dispatch('StageList')
  //   store.dispatch('LegalPackage')
  //   store.dispatch('LawyerCasePrice')
  //   store.dispatch('LawyerServiceScopeIds')
  // },
  render: h => h(App)
}).$mount('#app')
