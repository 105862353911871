<template>
  <div id="app">
    <!-- <div id="nav"> -->
      <!-- <router-link to="/Home"></router-link> | -->
    
    <!-- </div> -->
  
    
    <transition name='fade' mode="out-in">
      <router-view/>
    </transition>
  </div>
</template>


<script>


import { setToken } from '@/common/js/ut'
export default {
  name: 'app',
  components: {

  },
}
</script>

<style  lang="scss">
@import './common/css/reset';
@import './common/css/animation';
#app {
  height: 100%;
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>
