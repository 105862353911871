import Vue from 'vue'
import axios from 'axios'
// import store from '@/store'
import { VueAxios } from './axios'
import { ACCESS_TOKEN } from "@/store/mutation-types"

let apiBaseUrl = window._CONFIG['domianURL'] || "/ctc";
//console.log("apiBaseUrl= ",apiBaseUrl)
// 创建 axios 实例
const service = axios.create({
  //baseURL: '/jeecg-boot',
  baseURL: apiBaseUrl, // api base_url
  timeout: 9000 // 请求超时时间
})

// request interceptor
service.interceptors.request.use(config => {
    const token = Vue.ls.get(ACCESS_TOKEN)
    if (token) {
      config.headers[ 'X-Access-Token' ] = token // 让每个请求携带自定义 token 请根据实际情况自行修改
    }
    //update-begin-author:taoyan date:2020707 for:多租户
    // let tenantid = Vue.ls.get(TENANT_ID)
    // if (!tenantid) {
    //   tenantid = 0;
    // }
    // config.headers[ 'tenant_id' ] = tenantid
    //update-end-author:taoyan date:2020707 for:多租户
    // if(config.method=='get'){
    //   if(config.url.indexOf("sys/dict/getDictItems")<0){
    //     config.params = {
    //       _t: Date.parse(new Date())/1000,
    //       ...config.params
    //     }
    //   }
    // }
    return config
  },(error) => {
    return Promise.reject(error)
  })
  
  // response interceptor
  service.interceptors.response.use((response) => {
      return response.data
    }, (error) => {
        return Promise.reject(error)
    })


const installer = {
    vm: {},
    install (Vue, router = {}) {
      Vue.use(VueAxios, router, service)
    }
}
  
export {
  installer as VueAxios,
  service as axios
}