
import { login, logout, phoneLogin, thirdLogin, collectlist, loginPassword, h5Login } from "@/api/login"
import { ACCESS_TOKEN, USER_NAME, USER_INFO, USER_AUTH, SYS_BUTTON_AUTH, UI_CACHE_DB_DICT_DATA, TENANT_ID } from "@/store/mutation-types"
import { gainDictCode } from '@/api/api'

import { getAction, postAction } from "@/api/manage";

import Vue from 'vue'

const user = {
  state: {
    username: '',
    token: '',
    realname: '',
    avatar: '',
    info: '',
    stageList: [],
    manageServiceScopeIds: [],
    manageTitle: [],
    manageJobLevel: [],
    lawpackType: [],
    lawyerCasePriceIds: [], //可服务案件标的值
    lawyerServiceScopeIds: [],	//律师专家的服务
    collectList: [],
    job: [],
    custom: {},
    myUserInfo:{}
  },
  mutations: {
    SET_myUserInfo: (state, info) => {
      state.myUserInfo = info
      
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_NAME: (state, { username, realname }) => {
      state.username = username
      state.realname = realname
      //   console.log(state.username)
      //   state.welcome = welcome
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_STAGE: (state, list) => {
      state.stageList = list
    },
    SET_ALL_SERVICE_LIST: (state, list) => {
      state.manageServiceScopeIds = list
    },
    SET_MANAGE_TITLE: (state, list) => {
      state.manageTitle = list
    },
    SET_MANAGE_JOB_LEVEL: (state, list) => {
      state.manageJobLevel = list
    },
    SET_LEGAL_PACKAGE: (state, list) => {
      state.lawpackType = list
    },
    SET_LAWYER_CASE_PRICE: (state, list) => {
      state.lawyerCasePriceIds = list
    },
    SET_LAWYER_SERVICE_SCOPEDIDS: (state, list) => {
      state.lawyerServiceScopeIds = list
    },
    SET_COLLECT_LIST: (state, list) => {
      state.collectList = list
    },
    SET_JOB: (state, list) => {
      state.job = list
    },
    CUSTOM: (state, info) => {
      state.custom = info;
    },

  },
  actions: {
    setMyUserInfo({ commit }, info){
      Vue.ls.set(USER_INFO, info, 7 * 24 * 60 * 60 * 1000)
      commit('SET_myUserInfo',info)
    },
    PhoneLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        phoneLogin(userInfo).then(response => {
          if (response.code == '200') {
            const result = response.result
            const userInfo = result.userInfo
            const custom = result.custom
            Vue.ls.set('CUSTOM', custom, 7 * 24 * 60 * 60 * 1000);
            Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname })
            commit('SET_AVATAR', userInfo.avatar)
            commit('CUSTOM', custom)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    loginPassword({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        loginPassword(userInfo).then(response => {
          if (response.code == '200') {
            const result = response.result
            const userInfo = result.userInfo
            const custom = result.custom
            Vue.ls.set('CUSTOM', custom, 7 * 24 * 60 * 60 * 1000);
            Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
            Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
            commit('SET_TOKEN', result.token)
            commit('SET_INFO', userInfo)
            commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname })
            commit('SET_AVATAR', userInfo.avatar)
            commit('CUSTOM', custom)
            resolve(response)

          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        let logoutToken = state.token;
        //console.log('logoutToken: '+ logoutToken)
        logout(logoutToken).then((response) => {
          if (response.code === 200) {
            commit('SET_TOKEN', '')
            commit('SET_INFO', null)
            commit('SET_NAME', { username: '', realname: '' })
            Vue.ls.remove(USER_NAME)
            Vue.ls.remove(USER_INFO)
            Vue.ls.remove(ACCESS_TOKEN)
            Vue.ls.remove(UI_CACHE_DB_DICT_DATA)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch((error) => {
          resolve(error)
        })
      })

    },
    h5Login({ commit }, parameter) {

      postAction('/sys/h5Login', parameter).then(response => {
        if (response.code == '200') {
          
          const result = response.result
          const userInfo = result.userInfo
          const custom = result.custom
          localStorage.setItem("phone", userInfo.username);
          Vue.ls.set('CUSTOM', custom, 7 * 24 * 60 * 60 * 1000);
          Vue.ls.set(ACCESS_TOKEN, result.token, 7 * 24 * 60 * 60 * 1000)
          Vue.ls.set(USER_NAME, userInfo.username, 7 * 24 * 60 * 60 * 1000)
          Vue.ls.set(USER_INFO, userInfo, 7 * 24 * 60 * 60 * 1000)
          Vue.ls.set(UI_CACHE_DB_DICT_DATA, result.sysAllDictItems, 7 * 24 * 60 * 60 * 1000)
          commit('SET_TOKEN', result.token)
          commit('SET_INFO', userInfo)
          commit('SET_NAME', { username: userInfo.username, realname: userInfo.realname })
          commit('SET_AVATAR', userInfo.avatar)
          commit('CUSTOM', custom)
          resolve(response)

        } else {

        }
      }).catch(error => {

      }).then(() => {

      })

    },
    StageList({ commit, state }) {
      gainDictCode('manageServiceScopeIds').then(res => {
        if (res.code === 0) {
          let result = res.result;
          let stage_dicts = result.filter(item => Number(item.value) < 100)
          commit('SET_ALL_SERVICE_LIST', result)
          commit('SET_STAGE', stage_dicts)
        }
      })
    },
    ManageTitle({ commit, state }) {
      gainDictCode('manageTitle').then(res => {
        if (res.code === 0) {
          let result = res.result
          commit('SET_MANAGE_TITLE', result)
        }
      })
    },
    ManageJobLevel({ commit, state }) {
      gainDictCode('manageJobLevel').then(res => {
        if (res.code === 0) {
          let result = res.result
          commit('SET_MANAGE_JOB_LEVEL', result)
        }
      })
    },
    LegalPackage({ commit, state }) {
      gainDictCode('lawpackType').then(res => {
        if (res.code === 0) {
          let result = res.result
          commit('SET_LEGAL_PACKAGE', result)
        }
      })
    },
    LawyerCasePrice({ commit, state }) {
      gainDictCode('lawyerCasePriceIds').then(res => {
        if (res.code === 0) {
          let result = res.result
          commit('SET_LAWYER_CASE_PRICE', result)
        }
      })
    },
    LawyerServiceScopeIds({ commit, state }) {
      gainDictCode('lawyerServiceScopeIds').then(res => {
        if (res.code === 0) {
          let result = res.result
          commit('SET_LAWYER_SERVICE_SCOPEDIDS', result)
        }
      })
    },
    CollectList({ commit, state }) {
      collectlist().then(res => {
        if (res.success) {
          let result = res.result;
          // let list = result.map(item => item.outId)              
          // 收藏类型(0-专家,1-人才,2-课程,3-律师,4-招聘)
          commit('SET_COLLECT_LIST', result)
        }
      })
    },
    Job({ commit, state }) {
      gainDictCode('job').then(res => {
        if (res.code === 0) {
          let result = res.result
          commit('SET_JOB', result)
        }
      })
    }



  }
}


export default user
