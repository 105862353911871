

import { axios } from '@/utils2/http'
import { request } from '@/utils2/request'
import { getAction, postAction } from "@/api/manage";
export function phoneLogin (parameter) {
    return axios({
        url: '/sys/phoneLogin',
        method: 'post',
        data: parameter
    })
}
export function loginPassword (parameter) {
    return axios({
        url: '/sys/loginPassword',
        method: 'post',
        data: parameter
    })
}
export function logout (parameter) {
    return request({
        url: '/sys/logout',
        method: 'put',
        data: parameter
    })
}  

export function collectlist() {
    return request({
        url: '/favor/list',
        method: 'get',
    })
}
